.section.footer {
  min-height: 0;
}

.footer {
  @media screen and (max-width: 640px) {
    margin-top: -50px;
  }

  &__title {
    @include gradientText();
    font-size: 2.5rem;
    opacity: 0.85;

    @media screen and (max-width: 600px) {
      font-size: 2.25rem;
    }
  }

  &__social {
    margin: 50px 0;
    @include flex($direction: row, $justify: center, $gap: 1.5rem);
    flex-wrap: wrap;

    @media screen and (max-width: 740px) {
      margin-top: 30px;
      gap: 0.5rem;
    }

    &-box {
      @include flex($justify: left, $gap: 12px);
      border: 1px solid rgba(255, 255, 255, 0.292);
      width: 100%;
      padding: 12px;
      border-radius: 10px;
      position: relative;
      flex: 1 1 320px;

      @media screen and (min-width: 1100px) {
        flex: 0 1 450px;
      }

      &:hover {
        @supports (backdrop-filter: blur(2rem)) {
          background: rgba(73, 23, 120, 0.293);
          backdrop-filter: blur(2rem);
        }
      }
    }

    &-icon {
      width: 48px;
    }

    &-title {
      font-size: 1.05rem;
      font-weight: 600;
      color: $white;
    }

    &-desc {
      font-size: 0.85rem;
      color: $white;
      opacity: 0.5;
    }

    &-arrow {
      font-size: 1.4rem;
      position: absolute;
      right: 20px;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  &__text {
    text-align: center;
    padding: 10px 0 40px 0;
    opacity: 0.8;

    span {
      @include gradientText();
    }
  }
}
