.work {
  margin-bottom: 80px;

  &__projects {
    @include responsiveContainer($width: 320px, $gap: 2rem);
    margin: 2rem 0;
  }

  &__img {
    margin-top: 80px;
    max-width: 500px;
    margin-inline: auto;

    img {
      @include noSelect();
    }

    @media screen and (min-width: 800px) {
      display: none;
    }
  }
}

.project-card {
  @include gradientBackground();

  @supports (backdrop-filter: blur(2rem)) {
    background: rgba(73, 23, 120, 0.293);
    backdrop-filter: blur(2rem);
  }

  border-radius: 10px;
  padding: 15px;
  overflow: hidden;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);

  &:hover {
    transform: scale(1.01);
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  }

  &__img {
    width: 100%;

    img {
      width: 100%;
      height: 180px;
      object-fit: cover;
      object-position: center;
      border-radius: 10px;
    }
  }

  &__title {
    margin: 1.2rem 0 5px 0;
    font-size: 1.25rem;
  }

  &__desc {
    font-size: 0.9rem;
    opacity: 0.8;

    @include clampedText($lines: 3);
    margin-bottom: 10px;
  }
}
