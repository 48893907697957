.navbar {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 200;
  padding: 1.1rem 0;
  transition: all 0.3s ease-in-out;
  opacity: 0.8;

  &--hide {
    top: -100px;
  }

  &--shadow {
    @include gradientBackground();

    @supports (backdrop-filter: blur(2rem)) {
      background: rgba(73, 23, 120, 0.293);
      backdrop-filter: blur(2rem);
    }
  }

  &__container {
    @include flex($justify: space-between);
    @include noSelect;
  }

  &__logo {
    img {
      width: 52px;
      margin-left: -4px;
    }
  }

  &__actions {
    width: 100%;
    @include flex($justify: space-between);
  }

  &__links {
    @include flex($justify: space-between, $gap: 3.5rem);
    margin: auto;
  }

  &__link {
    font-weight: 400;
    padding: 0.3rem 0;
    border-radius: 0.5rem;

    a {
      @include flex($gap: 0.6rem);

      span {
        position: relative;
        color: $white;
        text-transform: capitalize;

        &,
        &::after,
        &::before {
          transition: all 0.3s ease;
        }

        &::after {
          position: absolute;
          bottom: -3px;
          left: 0;
          right: 0;
          margin: auto;
          width: 0%;
          content: '.';
          color: transparent;
          background: $white;
          height: 1px;
        }

        &:hover::after {
          width: 100%;
        }
      }
    }

    &-icon {
      @include flex();
      font-size: 1.2rem;
    }
  }

  &__socials {
    @include flex($justify: space-between, $gap: 0.8rem);
  }

  &__social {
    &-icon {
      @include flex($gap: 0.8rem);
      font-size: 1.5rem;
      transition: all 0.2s ease-in-out;

      &:hover {
        transform: scale(1.15);
      }
    }
  }
}
