.about {
  &__container {
    @include flex($direction: column, $justify: left);
  }

  &__img {
    border: 1px solid white;
    border-radius: 10px;
    width: 100%;
    height: 300px;
    margin-bottom: 20px;

    img {
      width: 350px;
      height: 290px;
      object-fit: cover;
      object-position: center;
      margin: auto;
    }
  }

  &__btn {
    margin: 40px 0;
  }
}
