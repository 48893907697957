@import 'reset';
@import 'variables';
@import 'config';
@import 'animations';
@import 'components';

:root {
  --clr-text-900: #ffffff;
  --clr-text-700: #dbdbdb;
  --clr-text-600: #d7d7d7;
  --clr-text-500: #c1c1c1;
  --clr-text-400: #acacac;
  --clr-text-300: #898989;
}

.app {
  @include flex($direction: column);
  min-height: 100vh;

  @include gradientBackground();
  color: $white;

  &-sections {
    width: 100%;
  }

  overflow: hidden;
}

.container {
  @include mainContainer();
}

.section {
  min-height: 100vh;
  width: 100%;
  @include flex();

  &__container {
    @include mainContainer();
  }

  &__title {
    text-align: center;
    font-family: 'Hammersmith One', sans-serif;
    font-size: 3rem;
    margin: 30px 0 20px 0;

    @media screen and (max-width: 600px) {
      font-size: 2.7rem;
    }
  }

  &__text {
    text-align: center;
    @include flex($direction: column, $gap: 1rem);
    opacity: 0.8;

    span {
      color: #a973fc;
    }

    @media screen and (max-width: 640px) {
      font-size: 0.95rem;
    }
  }
}

.download-btn {
  display: flex;
  width: max-content;
}

@import 'navbar';
@import 'hero';
@import 'about';
@import 'skills';
@import 'work';
@import 'contact';
@import 'footer';
