.contact {
  &__box {
    @include flex($direction: row-reverse, $justify: space-between, $gap: 2rem);

    @media screen and (max-width: 800px) {
      flex-direction: column;
    }
  }

  &__img {
    max-width: 500px;

    img {
      @include noSelect();
    }

    @media screen and (max-width: 800px) {
      display: none;
    }
  }

  &__form {
    @include flex($direction: column, $gap: 1rem);
    width: 100%;
    margin: 50px 0;
    padding: 30px 15px;
    max-width: 550px;
    border-radius: 10px;

    @media screen and (max-width: 800px) {
      max-width: 100%;
    }

    @supports (backdrop-filter: blur(2rem)) {
      background: rgba(73, 23, 120, 0.293);
      backdrop-filter: blur(2rem);
    }

    &-group {
      width: 100%;
      @include flex($direction: column, $align: left, $gap: 0.5rem);

      &-wrapper {
        width: 100%;
        @include flex($direction: row, $gap: 0.5rem);

        @media screen and (max-width: 300px) {
          flex-direction: column;
        }
      }
    }

    label {
      display: none;
    }

    input {
      border: 1px solid rgba(255, 255, 255, 0.292);
      border-radius: 5px;
      padding: 0.5rem;
      outline: none;
      font-size: 0.95rem;
      color: #cfbfdd;
      background: transparent;
      transition: all 0.3s ease-in-out;
      width: 100%;
      height: 45px;
      min-width: 100%;

      &:focus {
        border: 1px solid #702cac;
        box-shadow: 0 0 5px #702cac;
      }

      &::placeholder {
        color: #a38ab9;
      }
    }

    textarea {
      border: 1px solid rgba(255, 255, 255, 0.292);
      border-radius: 5px;
      padding: 0.5rem;
      outline: none;
      font-size: 0.95rem;
      color: #cfbfdd;
      background: transparent;
      transition: all 0.3s ease-in-out;
      width: 100%;
      height: 150px;
      min-height: 45px;
      resize: vertical;

      &:focus {
        border: 1px solid #702cac;
        box-shadow: 0 0 5px #702cac;
      }

      &::placeholder {
        color: #a38ab9;
      }
    }

    button {
      margin-top: 10px;
      width: 100%;
      height: 45px;
    }
  }
}
