.hero {
  &__container {
    @include flex($align: center, $justify: space-between);

    @media screen and (max-width: $mobile-breakpoint) {
      flex-direction: column-reverse;
      gap: 5rem;
    }
  }

  &__text,
  &__image {
    flex: 0 0 50%;
    max-width: 50%;
    @include noSelect();

    @media screen and (max-width: $mobile-breakpoint) {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }

  &__text {
    &-icon {
      animation-name: wave-animation;
      animation-duration: 2.5s;
      animation-iteration-count: infinite;
      transform-origin: 70% 70%;
      display: inline-block;
    }

    &-name {
      font-family: 'Poppins', sans-serif;
      font-size: 3rem;
      font-weight: 800;
      color: var(--clr-text-500);

      span {
        @include gradientText();
      }
    }

    &-desc {
      font-family: 'Poppins', sans-serif;
      font-size: 1.5rem;
      font-weight: 500;
      color: var(--clr-text-500);
    }

    &-hello {
      font-family: 'Poppins', sans-serif;
      font-size: 1rem;
      font-weight: 300;
      color: var(--clr-text-500);
    }

    @media screen and (min-width: 1500px) {
      &-name {
        font-size: 3.5rem;
      }

      &-desc {
        font-size: 1.6rem;
      }

      &-hello {
        font-size: 1.5rem;
      }
    }

    @media screen and (max-width: 850px) {
      &-name {
        font-size: 3rem;
      }

      &-desc {
        font-size: 1.2rem;
      }
    }

    @media screen and (max-width: 720px) {
      &-desc {
        font-size: 1.2rem;
      }
    }

    @media screen and (max-width: $mobile-breakpoint) {
      @include flex($direction: column, $gap: 0);
      text-align: center;
    }

    @media screen and (max-width: 380px) {
      &-name {
        font-size: 2.2rem;
      }

      &-desc {
        font-size: 1rem;
      }
    }

    @media screen and (max-width: 330px) {
      &-name {
        font-size: 2.15rem;
      }

      &-desc {
        font-size: 0.95rem;
      }

      &-hello {
        font-size: 0.8rem;
      }
    }

    @media screen and (max-width: 270px) {
      &-name {
        font-size: 2rem;
      }

      &-desc {
        font-size: 0.8rem;
      }

      &-hello {
        font-size: 0.6rem;
      }
    }
  }

  &__img {
    max-width: 450px;

    img {
      @include noSelect();
    }

    @media screen and (max-width: $mobile-breakpoint) {
      max-width: min(300px, 92%);
    }
  }

  &__btn {
    margin-top: 30px;
  }
}
