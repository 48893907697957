$clr-primary: #541ba7;

.btn {
  width: max-content;
  cursor: pointer;

  @include flex($gap: 0.8rem);
  padding: 0 16px;
  height: 42px;

  color: var(--clr-text-600);
  border: none;
  border-radius: 10px;

  @include gradientComponent();

  font-weight: 600;
  transition: all 0.3s ease-in-out;

  &-icon {
    display: flex;
    align-items: center;
  }

  &[disabled] {
    cursor: not-allowed;
    opacity: 0.6;
  }

  &:hover {
    translate: 0 -5px;
  }

  &--outline {
    background: transparent;
    color: $clr-primary;
    border: 2px solid $clr-primary;
    transition-duration: 0.1s;

    &:hover {
      @include gradientComponent();
      color: var(--clr-text-500);
    }
  }

  &--link {
    background: transparent;
    color: $clr-primary;
    border: none;
    padding: 2px 5px;
    text-transform: none;

    &:focus {
      outline: none;
    }

    &:hover {
      text-decoration: underline;
    }
  }
}

.theme-toggle-btn {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 400;

  border: none;
  outline: none;
  cursor: pointer;

  font-size: 1.2rem;
  color: var(--clr-text-500);

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;

    &.moon {
      font-size: 1.4rem;
    }
  }
}

.shadow-text {
  width: 100vw;
  height: 100vh;
  @include flex();

  &__letter {
    position: relative;
    text-transform: uppercase;
    text-shadow: -15px 5px 20px #ced0d3;
    color: white;
    letter-spacing: -0.05em;
    font-family: 'Anton', Arial, sans-serif;
    user-select: none;
    text-transform: uppercase;
    font-size: 150px;
    transition: all 0.25s ease-out;

    &:hover {
      text-shadow: -16px 6px 15px #ced0d3;
    }
  }
}
