@mixin flex($align: center, $justify: center, $direction: row, $gap: 0) {
  display: flex;
  align-items: $align;
  justify-content: $justify;
  flex-direction: $direction;
  gap: $gap;
}

@mixin grid($columns, $gap) {
  display: grid;
  grid-template-columns: $columns;
  grid-gap: $gap;
}

@mixin responsiveContainer($width, $gap) {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(min($width, 100%), 1fr));
  gap: $gap;
}

@mixin mainContainer {
  width: 92%;
  max-width: 1120px;
  margin: auto;
}

@mixin clampedText($lines: 2) {
  display: -webkit-box;
  -webkit-line-clamp: $lines;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

@mixin gradientBackground($direction: right) {
  background-color: #21053b;

  background-image: -webkit-linear-gradient(
    to $direction,
    #38094d,
    #310845,
    #2a073e,
    #27073c,
    #250639,
    #220537,
    #210539,
    #21053b,
    #20063d,
    #1f063f
  );

  background-image: linear-gradient(
    to $direction,
    #38094d,
    #310845,
    #2a073e,
    #27073c,
    #250639,
    #220537,
    #210539,
    #21053b,
    #20063d,
    #1f063f
  );
}

@mixin gradientText {
  background-color: #b673f8;
  background-image: -webkit-linear-gradient(
    90deg,
    #4ca5ff 2.34%,
    #b673f8 100.78%
  );
  background-image: linear-gradient(90deg, #4ca5ff 2.34%, #b673f8 100.78%);
  background-clip: text;
  color: transparent;
}

@mixin gradientComponent($direction: right) {
  background-color: #541ba7;
  background-image: -webkit-linear-gradient(
    to $direction,
    #361898,
    #45199f,
    #541ba7,
    #621cae,
    #701db5
  );
  background-image: linear-gradient(
    to $direction,
    #361898,
    #45199f,
    #541ba7,
    #621cae,
    #701db5
  );
}

@mixin noSelect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
