.skills {
  padding-top: 30px;
  margin-bottom: 100px;

  &__container {
    @include flex($direction: column);
  }

  &__cards-container {
    @include responsiveContainer($width: 40px, $gap: 3rem);
  }
}

.skill-card {
  @include flex($direction: column, $gap: 1rem);

  &__img {
    width: 100%;
  }

  &__title {
    font-size: 0.8rem;
  }
}
