*,
*::before,
*::after {
  box-sizing: border-box;
}

* {
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
}

ul[role='list'],
ol[role='list'] {
  list-style: none;
}

html:focus-within {
  scroll-behavior: smooth;
}

a:not([class]) {
  text-decoration-skip-ink: auto;
}

img,
picture {
  max-width: 100%;
  display: block;
}

input,
button,
textarea,
select {
  font: inherit;
}

li {
  list-style: none;
}

a {
  text-decoration: none;
  color: inherit;
}

body {
  font-family: 'Quicksand', sans-serif;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
}

h1,
h2,
h3,
h4 {
  font-family: 'Hammersmith One', sans-serif;
}
